// Here you can add other styles
.login-logo {
    position: relative;

    &::after {
        position: absolute;
        content: "Powered by Redgrape Technologies";
        left: 0;
        bottom: 0;
        right: 0;
        height: 13%;
        background-color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
    }
}

.haatnow-sidebar {
    &.sidebar {
        --cui-sidebar-width: 16rem;
        --cui-sidebar-bg: #f8f8f8;
        --cui-sidebar-padding-x: 0;
        --cui-sidebar-padding-y: 0;
        --cui-sidebar-color: rgba(255, 255, 255, 0.87);
        --cui-sidebar-border-width: 0;
        --cui-sidebar-border-color: transparent;
        --cui-sidebar-brand-color: rgba(255, 255, 255, 0.87);
        --cui-sidebar-brand-height: 4rem;
        --cui-sidebar-brand-bg: rgba(0, 0, 21, 0.2);
        --cui-sidebar-header-height: 4rem;
        --cui-sidebar-header-bg: rgba(0, 0, 21, 0.2);
        --cui-sidebar-header-padding-x: 1rem;
        --cui-sidebar-header-padding-y: 0.75rem;
        --cui-sidebar-footer-bg: rgba(0, 0, 21, 0.2);
        --cui-sidebar-footer-height: auto;
        --cui-sidebar-footer-padding-x: 1rem;
        --cui-sidebar-footer-padding-y: 0.75rem;
        --cui-sidebar-toggler-bg: rgba(0, 0, 21, 0.2);
        --cui-sidebar-toggler-height: 3rem;
        --cui-sidebar-toggler-indicator-width: 4rem;
        --cui-sidebar-toggler-indicator-height: 3rem;
        --cui-sidebar-toggler-hover-bg: rgba(0, 0, 0, 0.3);
        --cui-sidebar-narrow-width: 4rem;
        --cui-sidebar-nav-title-padding-x: 1rem;
        --cui-sidebar-nav-title-padding-y: 0.75rem;
        --cui-sidebar-nav-title-margin-top: 1rem;
        --cui-sidebar-nav-title-color: rgba(0, 0, 0, 0.8);
        --cui-sidebar-nav-link-padding-x: 1rem;
        --cui-sidebar-nav-link-padding-y: 0.8445rem;
        --cui-sidebar-nav-link-color: rgba(0, 0, 0, 0.8);
        --cui-sidebar-nav-link-bg: transparent;
        --cui-sidebar-nav-link-border-color: transparent;
        --cui-sidebar-nav-link-border: 0 solid var(--cui-sidebar-nav-link-border-color);
        --cui-sidebar-nav-link-border-radius: 0;
        --cui-sidebar-nav-link-active-color: rgba(255, 255, 255, 0.87);
        --cui-sidebar-nav-link-active-bg: rgba(0, 0, 0, 0.2);
        --cui-sidebar-nav-link-active-icon-color: rgba(255, 255, 255, 0.87);
        --cui-sidebar-nav-link-disabled-color: rgba(0, 0, 0, 0.5);
        --cui-sidebar-nav-link-disabled-icon-color: rgba(255, 255, 255, 0.6);
        --cui-sidebar-nav-link-hover-color: rgba(255, 255, 255, 0.87);
        --cui-sidebar-nav-link-hover-bg: rgba(0, 0, 0, 0.2);
        --cui-sidebar-nav-link-hover-icon-color: rgba(255, 255, 255, 0.87);
        --cui-sidebar-nav-icon-width: 4rem;
        --cui-sidebar-nav-icon-height: 1.25rem;
        --cui-sidebar-nav-icon-font-size: 1.25rem;
        --cui-sidebar-nav-link-icon-color: rgba(0, 0, 0, 0.8);
        --cui-sidebar-nav-group-bg: rgba(0, 0, 0, 0.2);
        --cui-sidebar-nav-group-items-padding-y: 0;
        --cui-sidebar-nav-group-items-padding-x: 0;
        --cui-sidebar-nav-group-toggle-show-color: rgba(255, 255, 255, 0.6);
        .sidebar-nav .nav-group.show .nav-group-toggle .nav-icon {
            color: var(--cui-sidebar-nav-link-hover-icon-color);
        }
        .sidebar-nav .nav-group-items .nav-link {
            color: var(--cui-sidebar-nav-link-hover-icon-color);
        }
    }
}