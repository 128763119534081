$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";


.search-dropdown {
    :where(.css-dev-only-do-not-override-17sses9).ant-select-single {
        height : 40px !important
    }

    .search-btn{
        height: 40px !important
    }
}